<template>
  <div class="mb-4">
    <b-alert :variant="'primary'" show class="cursor-pointer">
      <div class="alert-body">
        <strong>
          <feather-icon icon="BookOpenIcon" class="mr-25" />
          Every great system starts with a solid foundation. Create forms
          effortlessly to capture data and manage entries for seamless
          operations.
        </strong>
      </div>
    </b-alert>
    <b-row class="match-height">
      <b-col cols="12" md="12">
        <b-card>
          <validation-observer ref="yoBadgeRule" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  <b-badge pill variant="primary"> A </b-badge>
                  Badge Info
                </h5>
                <small class="text-muted">
                  <strong>Enter Your Badge Info.</strong>
                </small>
              </b-col>
              <b-col md="12" cols="12">
                <validation-provider
                  #default="{ errors }"
                  name="Badge"
                  rules="required"
                >
                  <b-form-group
                    label="Badge"
                    label-for="badge_id"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="badge_id"
                      v-model="form.badge_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="badgeNameLogin"
                      :reduce="(option) => option.value"
                      :selectable="
                        (option) => !option.value.includes('select_value')
                      "
                      label="text"
                      item-value="value"
                      item-text="text"
                    >
                      <template #option="{ avatar, text }">
                        <!-- <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${color}`"
                      style="height:10px;width:10px"
                    /> -->
                        <span class="ml-50 align-middle"> {{ text }}</span>
                      </template>

                      <template #selected-option="{ avatar, text }">
                        <!-- <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${color}`"
                      style="height:10px;width:10px"
                    /> -->
                        <span class="ml-50 align-middle"> {{ text }}</span>
                      </template>
                    </v-select>
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="12" cols="12">
                <!-- Phone -->
                <b-form-group
                  label="Nomor Whatsapp * ( Ex: 8155 7777 33 )"
                  label-for="phone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nomor Whatsapp *"
                    vid="phone"
                    :rules="{
                      required: true
                    }"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        ID (+62)
                      </b-input-group-prepend>
                      <cleave
                        id="phone"
                        v-model="form.phone"
                        class="form-control"
                        :raw="false"
                        :options="options.phone"
                        placeholder="8155 7777 33"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card>
      </b-col>

      <b-col cols="12" md="6" v-if="false">
        <b-card>
          <validation-observer ref="yoSocialLinksRule" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  <b-badge pill variant="primary"> B </b-badge> Social Links
                  <b-badge variant="success"> Opsional </b-badge>
                </h5>
                <small class="text-muted">
                  <strong>Enter Your Social Links Opsional</strong>
                </small>
              </b-col>
              <b-col md="6" cols="12">
                <b-form-group label="Twitter" label-for="twitter">
                  <validation-provider
                    #default="{ errors }"
                    name="twitter"
                    rules="twitter"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <feather-icon icon="TwitterIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="twitter"
                        v-model="form.twitter"
                        type="twitter"
                        :state="errors.length > 0 ? false : null"
                        placeholder="salpalaran"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" cols="12">
                <b-form-group label="Facebook" label-for="facebook">
                  <validation-provider
                    #default="{ errors }"
                    name="facebook"
                    rules="facebook"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <feather-icon icon="FacebookIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="facebook"
                        v-model="form.facebook"
                        type="facebook"
                        :state="errors.length > 0 ? false : null"
                        placeholder="salpalaran"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" cols="12">
                <b-form-group label="Instagram" label-for="instagram">
                  <validation-provider
                    #default="{ errors }"
                    name="instagram"
                    rules="instagram"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <feather-icon icon="InstagramIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="instagram"
                        v-model="form.instagram"
                        type="instagram"
                        :state="errors.length > 0 ? false : null"
                        placeholder="salpalaran"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" cols="12">
                <b-form-group label="Linkedin" label-for="linkedin">
                  <validation-provider
                    #default="{ errors }"
                    name="linkedin"
                    rules="linkedin"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <feather-icon icon="LinkedinIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="linkedin"
                        v-model="form.linkedin"
                        type="linkedin"
                        :state="errors.length > 0 ? false : null"
                        placeholder="salpalaran"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card>
      </b-col>

      <b-col cols="12" md="6">
        <b-card>
          <validation-observer ref="yoPersonalInfoRule" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  <b-badge pill variant="primary"> B </b-badge> Personal Info
                </h5>
                <small class="text-muted">
                  <strong>Enter Your Personal Info.</strong>
                </small>
              </b-col>
              <b-col md="12" cols="12">
                <b-card
                  border-variant="primary"
                  header-border-variant="primary"
                  header-text-variant="primary"
                >
                  <b-row>
                    <b-col md="12" cols="12">
                      <h4 class="mb-1">Document KTP</h4>
                      <b-img
                        ref="refPreviewEl"
                        :src="no_ktp_file"
                        height="110"
                        width="170"
                        class="rounded mr-2 mb-1 mb-md-0"
                      />
                      <b-form-group
                        class="mt-1"
                        label=""
                        label-for="vi-schedule-no_ktp_file"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="File KTP"
                          vid="File KTP"
                          rules="required"
                        >
                          <b-form-file
                            ref="refInputEl"
                            v-model="form.no_ktp_file"
                            placeholder="Choose a file KTP or drop it here..."
                            accept=".jpeg, .jpg, .png"
                            :state="errors.length > 0 ? false : null"
                            @input="inputImageRenderer"
                          />
                        </validation-provider>
                      </b-form-group>
                      <div v-if="text">
                        <b-card
                          border-variant="success"
                          header-border-variant="success"
                          header-text-variant="success"
                        >
                          <small
                            >System berhasil membaca
                            <strong>Nomor NIK : </strong>
                            <span
                              class="cursor-pointer"
                              v-b-tooltip.hover
                              title="Click to copy"
                              @click="copyText(text)"
                              >{{ text }} <feather-icon icon="CopyIcon" />
                            </span>
                            , jika benar maka Click nomor NIK (copy) dan paste
                            ke kolom pengisian
                            <strong>Nomor KTP</strong>.</small
                          >
                        </b-card>
                      </div>
                    </b-col>
                    <b-col md="12" cols="12">
                      <b-row>
                        <b-col cols="12">
                          <b-form-group label="Nomor KTP *" label-for="no_ktp">
                            <validation-provider
                              #default="{ errors }"
                              name="Nomor KTP *"
                              :rules="{
                                required: true,
                                min: 16
                              }"
                            >
                              <b-input-group>
                                <b-input-group-prepend is-text>
                                  <feather-icon icon="KeyIcon" />
                                </b-input-group-prepend>
                                <cleave
                                  id="no_ktp"
                                  v-model="form.no_ktp"
                                  class="form-control"
                                  :raw="true"
                                  :options="options.noKTP"
                                  placeholder="Nomor KTP"
                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="6" cols="12">
                          <b-form-group
                            label="Nama Lengkap *"
                            label-for="full_name"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Nama Lengkap *"
                              rules="required"
                            >
                              <b-input-group>
                                <b-input-group-prepend is-text>
                                  <feather-icon icon="UserIcon" />
                                </b-input-group-prepend>

                                <b-form-input
                                  v-uppercase
                                  id="full_name"
                                  v-model="form.full_name"
                                  :state="errors.length > 0 ? false : null"
                                  placeholder="Nama Lengkap"
                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="6" cols="12">
                          <b-form-group
                            label="Email ( Opsional )"
                            label-for="email"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="email"
                              rules="email"
                            >
                              <b-input-group>
                                <b-input-group-prepend is-text>
                                  <feather-icon icon="MailIcon" />
                                </b-input-group-prepend>
                                <b-form-input
                                  id="email"
                                  v-model="form.email"
                                  type="email"
                                  :state="errors.length > 0 ? false : null"
                                  placeholder="email@sal.com"
                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </validation-observer>
          <strong>
            <i>Sudah pernah memiliki QRCode ?</i>
            <span
              @click="$router.push({ name: 'reuse-qrcode' })"
              style="cursor: pointer; color: blue"
            >
              Click Reuse QRCode
            </span>
          </strong>
        </b-card>
      </b-col>
      <b-col cols="12" md="6">
        <b-card>
          <validation-observer ref="yoScheduleRule" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  <b-badge pill variant="primary"> C </b-badge> Schedule IN
                </h5>
                <small class="text-muted">
                  <strong>Enter Your Planning.</strong>
                </small>
              </b-col>
              <b-col>
                <!-- Calendar for selecting the date -->
                <b-form-group label="">
                  <validation-provider
                    #default="{ errors }"
                    name="Schedule IN Date"
                    rules="required"
                  >
                    <b-calendar
                      v-model="form.schedule_in_date"
                      block
                      locale="id-ID"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Time picker for selecting the time -->
                <b-form-group label="">
                  <validation-provider
                    #default="{ errors }"
                    name="Schedule IN Time"
                    rules="required"
                  >
                    <b-form-timepicker
                      v-model="form.schedule_in_time"
                      now-button
                      reset-button
                      locale="id-ID"
                      :hour12="false"
                      class="mt-2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <strong>
                  <br />
                  <i>" Every scan tells a story of protection and control. "</i>
                </strong>
                <!-- <p>Selected Date: {{ form.schedule_in_date }}</p>
                <p>Selected Time: {{ form.schedule_in_time }}</p> -->
              </b-col>
            </b-row>
          </validation-observer>
        </b-card>
      </b-col>

      <b-col cols="12" md="12">
        <b-card>
          <validation-observer ref="yoScheduleOthers" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  <b-badge pill variant="primary"> E </b-badge> Others
                </h5>
                <small class="text-muted">
                  <strong>Enter Your Descriptions / Others.</strong>
                </small>
              </b-col>
              <b-col md="6" cols="12">
                <b-form-group label="Alamat *" label-for="address">
                  <validation-provider
                    #default="{ errors }"
                    name="Alamat *"
                    rules="required"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <feather-icon icon="MapIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="address"
                        v-model="form.address"
                        type="address"
                        :state="errors.length > 0 ? false : null"
                        placeholder="C5QH+98X, Rw., Rw. Makmur, Kec. Palaran, Kota Samarinda, Kalimantan Timur 75251"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" cols="12">
                <b-form-group
                  label="Asal Perusahaan / Instansi"
                  label-for="from_company"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Asal Perusahaan / Instansi *"
                    rules="required"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <feather-icon icon="MapPinIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="from_company"
                        v-uppercase
                        v-model="form.from_company"
                        type="from_company"
                        :state="errors.length > 0 ? false : null"
                        placeholder="PT SARANA ABADI LESTARI"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" cols="12">
                <b-form-group label="Ket. / Tujuan Kunjungan *" label-for="ket">
                  <validation-provider
                    #default="{ errors }"
                    name="Ket. / Tujuan Kunjungan *"
                    rules="required"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <feather-icon icon="CoffeeIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="ket"
                        v-uppercase
                        v-model="form.ket"
                        type="ket"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Example: Monthly Meeting K3A, Daily Checking, etc"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card>
      </b-col>

      <b-col cols="12">
        <b-button
          :disabled="loadingSaveData"
          block
          @click="formSubmitted"
          variant="primary"
        >
          <b-spinner v-if="loadingSaveData" small />
          Simpan data dan Kirim QRCode Ke Whatsapp
          {{ form.phone ? `+62 ${form.phone}` : `` }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BAlert,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BBadge,
  BFormInput,
  BFormInvalidFeedback,
  BFormFile,
  BInputGroup,
  BInputGroupPrepend,
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BAvatar,
  BCalendar,
  BFormTimepicker,
  BImg
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import useJwt from '@/auth/jwt/useJwt'
import Tesseract from 'tesseract.js'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    BAlert,
    BButton,
    BFormTimepicker,
    BCalendar,
    BBadge,
    Cleave,
    BImg,
    BAvatar,
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BInputGroup,
    BInputGroupPrepend,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userDataVisitor')) ?? [],
      loadingSaveData: false,
      badge: [
        {
          id: 1,
          name: 'Tamu ( Visitor )',
          code: 'A.1',
          desc: 'Umum'
        },
        {
          id: 2,
          name: 'Awak 2 Truck',
          code: 'A.2',
          desc: ''
        },
        {
          id: 3,
          name: 'Identitas / Karyawan / Rutinitas',
          code: 'B',
          desc: ''
        },
        {
          id: 4,
          name: 'Pekerja Harian',
          code: 'C',
          desc: ''
        },
        {
          id: 5,
          name: 'Pengurus Agent BBM',
          code: 'D',
          desc: ''
        }
      ],
      no_ktp_file: {},
      options: {
        phone: {
          // phone: true,
          // phoneRegionCode: 'ID',
          numericOnly: true,
          delimiters: [' ', ' ', ' '],
          blocks: [4, 4, 4]
        },
        noKTP: {
          numericOnly: true,
          delimiters: [' ', ' ', ' '],
          blocks: [4, 4, 4, 4],
          uppercase: true
        }
      },
      /*
      visitor_public --> id badge_id
      visitor_private --> id badge_id

      ----------------
      ( Reuse QRCode update date_expired and status )
      ( sys_auto_renew = true kalau badge_id = 3 = Reuse QRCode )
      ----------------

      visitor_qrcode --> id model model_id qrcode_img status date_expired sys_auto_renew

      visitor_count --> id id_visitor_qrcode clock_in clock_out diff_time

      ----------------
      index agent
      vw_visitor_qrcode_agent --> id qrcode_img status expired visitor_private ( personal information )

      index user
      vw_visitor_qrcode_user -->  id qrcode_img status expired visitor_public ( personal information )
      ----------------
      */
      form: {
        /* personal informations */
        no_ktp_file: '',
        no_ktp: '',
        full_name: '',
        email: '',
        phone: '',

        /* type data / badge
         *
         * Keterangan - Code - Name
         * 1. Umum A.1 Tamu ( Visitor )
         * 2. Jalur Darat A.2 Tamu ( Visitor )
         * 3. '' B Identitas / Karyawan / Rutinitas
         * 4. '' C Pekerja Harian
         * 4. '' 5 Pengurus Agent BBM
         *
         **/
        badge_id: '',

        /* social media */
        twitter: '',
        facebook: '',
        instagram: '',
        linkedin: '',
        schedule_in_date: '',
        schedule_in_time: '',
        uuid: uuidv4(),
        ket: ''
      },
      required,
      email,
      badgeName: [
        {
          color: '',
          value: 'select_value',
          text: 'Pilih Badge ( Pilihan Terbatas Login Required )'
        },
        {
          color: 'primary',
          value: '1',
          text: '[ A.1 ] Tamu ( Visitor ) - Umum'
        },
        {
          color: 'primary',
          value: '2',
          text: '[ A.2 ] Awak 2 Truck'
        },
        {
          color: 'success',
          value: '3',
          text: '[ B ] Identitas / Karyawan / Rutinitas'
        },
        {
          color: 'warning',
          value: '4',
          text: '[ C ] Pekerja Harian'
        },
        {
          color: 'success',
          value: '5',
          text: '[ D ] Pengurus Agent BBM'
        }
      ]
    }
  },
  setup() {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)
    const text = ref('') // Create a ref to store the extracted NIK

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64
        extractText(base64)
      }
    )

    // Moved extractText function here so it can access text ref
    const extractText = (base64Image) => {
      Tesseract.recognize(
        base64Image,
        'eng', // use 'ind' if the text is in Indonesian
        {
          logger: (m) => {
            console.log(m)
          } // Optional: log progress
        }
      )
        .then(({ data: { text: extractedText } }) => {
          console.log('Extracted Text:', extractedText)

          // Clean the extracted text by removing non-alphanumeric characters
          // (with exceptions for spaces for clearer parsing)
          const cleanedText = extractedText
            .replace(/[^\w\s:]/g, ' ')
            .replace(/\s+/g, ' ')
            .trim()
          console.log('Cleaned Text:', cleanedText)

          // Use regex to extract NIK number
          const nikMatch = cleanedText.match(/NIK\s*:\s*(\w+)/i)

          if (nikMatch && nikMatch[1]) {
            const nik = nikMatch[1] // get the first capturing group which is the number
            console.log('Extracted NIK:', nik)

            // Check if the first character is 'b' and replace it with '6'
            if (nik[0] === 'b') {
              const updatedNik = '6' + nik.slice(1) // Create a new string with '6' as the first character
              text.value = updatedNik // Store the updated NIK in the reactive ref
            } else {
              text.value = nik // Store the extracted NIK in the reactive ref
            }
          } else {
            console.log('NIK not found')
            text.value = ''
          }
        })
        .catch((err) => {
          console.error(err)
        })
    }

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
      text
    }
  },
  computed: {
    badgeNameLogin() {
      if (this.userData.length !== 0) {
        return this.badgeName.map((item) => {
          if (item.value === 'select_value') {
            return {
              color: '',
              value: 'select_value',
              text: 'Pilih Badge'
            }
          }
          return item
        })
      } else {
        const data = this.badgeName.filter(
          (d) => d.value != '3' && d.value != '4' && d.value != '5'
        )
        return data
      }
    }
  },
  methods: {
    copyText(string) {
      this.$copyText(string).then(
        () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Text copied : ${string}`,
              variant: 'primary',
              icon: 'CheckCircleIcon'
            }
          })
        },
        (e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Can not copy!`,
              variant: 'danger',
              icon: 'XCircleIcon'
            }
          })
        }
      )
    },
    async formSubmitted() {
      try {
        await this.validationA().then(async (_) => {
          await this.validationC().then(async () => {
            await this.validationD().then(async () => {
              await this.validationE().then(() => {
                this.saveData()
              })
            })
          })
        })
      } catch (error) {
        console.log('errors', error)
      }
    },
    async saveData() {
      try {
        this.loadingSaveData = true
        const formData = new FormData()
        formData.append('file', this.form.no_ktp_file)

        // Trim the phone number and remove any spaces
        let phoneNumber = this.form.phone.trim().replace(/\s+/g, '')

        if (phoneNumber.startsWith('0')) {
          // Remove the leading '0' if it exists
          phoneNumber = phoneNumber.slice(1)
        }

        // Now prepend the country code
        const formattedPhone = `62${phoneNumber}`
        console.log('sent to : ', formattedPhone)
        const payload = {
          ...this.form,
          phone: `${formattedPhone}`
          // img_url: `https://salpalaran.com/img/faq-illustrations.06fc90e5.svg`,
          // desc: `QRCode`,
          // msg_text: `[QRCode] Anda berhasil membuat planning tujuan ke PT Sarana Abadi Lestari`,
        }
        // Append the payload as a JSON string
        // formData.append('payload', JSON.stringify(payload))

        await useJwt.http
          .post('visitor_public/', payload)
          .then(async (result) => {
            try {
              const { id } = result.data.visitor_public
              await useJwt.http
                .put(
                  `file_storage_ktp/${id}/visitor_public/${payload.no_ktp}`,
                  formData
                )
                .then(async (_result) => {
                  console.log('[post ktp] [0] success on upload ktp', _result)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'QRCode Telah dikirim ke Whatsapp Kamu',
                      icon: 'SaveIcon',
                      variant: 'success'
                    }
                  })
                  const successData = {
                    full_name: this.form.full_name,
                    phone: this.form.phone
                  }

                  // Store the data in Vuex
                  this.$store.commit('visitor/SET_SUCCESS_DATA', successData)

                  this.$router.push({
                    path: '/planning/create-form-umum/success'
                  })
                  // await this.sentNotification('Published')
                  this.loadingSaveData = false
                })
                .catch((errors) => {
                  this.loadingSaveData = false
                  console.log('[post ktp] [1] an errors on upload ktp', errors)
                })
            } catch (errors) {
              this.loadingSaveData = false
              console.log('[post ktp] [2] an errors on upload ktp', errors)
            }
          })
          .catch((e) => {
            this.loadingSaveData = false
            console.log('error: ', e)
            console.log('error: ', e.data.message.default)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Tidak berhasil melakukan aksi',
                icon: 'SaveIcon',
                variant: 'danger'
              }
            })
          })
      } catch (error) {
        console.log('an errors', error)
      }
    },
    // async sentNotification(isRevisiMsg) {
    //   await this.setPayloadNotification(isRevisiMsg)
    //   useJwt.http
    //     .post('notification', this.payloadNotification)
    //     .then(() => {})
    //     .catch((error) => {
    //       // console.log('data err: ', err)
    //       this.$swal({
    //         title: 'Error!',
    //         text: ` Form Masuk dan Keluar Berhasil dibuat tetapi ada kesalahan sistem untuk notification! ${error}. Ini tidak berdampak pada kerja sistem utama.`,
    //         icon: 'error',
    //         customClass: {
    //           confirmButton: 'btn btn-primary'
    //         },
    //         buttonsStyling: false
    //       }).then(async () => {
    //         if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
    //         this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
    //       })
    //     })
    // },
    validationA() {
      return new Promise((resolve, reject) => {
        this.$refs.yoBadgeRule.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Form Wajib diisi',
                icon: 'SaveIcon',
                variant: 'danger'
              }
            })
            reject()
          }
        })
      })
    },
    validationC() {
      return new Promise((resolve, reject) => {
        this.$refs.yoPersonalInfoRule.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Form Wajib diisi',
                icon: 'SaveIcon',
                variant: 'danger'
              }
            })
            reject()
          }
        })
      })
    },
    validationD() {
      return new Promise((resolve, reject) => {
        this.$refs.yoScheduleRule.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Form Wajib diisi',
                icon: 'SaveIcon',
                variant: 'danger'
              }
            })
            reject()
          }
        })
      })
    },
    validationE() {
      return new Promise((resolve, reject) => {
        this.$refs.yoScheduleOthers.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Form Wajib diisi',
                icon: 'SaveIcon',
                variant: 'danger'
              }
            })
            reject()
          }
        })
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
